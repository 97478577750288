
document.addEventListener("turbolinks:load", function(event) {
  check_to_hide_or_show_add_link();

  document.addEventListener('cocoon:after-insert', function() {
    check_to_hide_or_show_add_link();
  });

  document.addEventListener('cocoon:after-remove', function() {
    check_to_hide_or_show_add_link();
  });
});


function check_to_hide_or_show_add_link() {
  if(document.getElementById('btnAdd') != null){
    var visibleSubstances = document.querySelectorAll(".nested-fields:not([style='display: none;'])");
    if (visibleSubstances.length == 4) {
      document.getElementById('btnAdd').parentNode.classList.add("collapsed");
    } else {
      document.getElementById('btnAdd').parentNode.classList.remove("collapsed");
    }
  }
}
