
document.addEventListener("turbolinks:load", function(event) {
  var tablinks = document.getElementsByClassName("tablinks");
  if(tablinks.length > 0 && sessionStorage.getItem("selectedTab") == "null"){
    changeTab('Prescription');
  }
});

document.addEventListener("turbolinks:load", function(event) {
  let top = localStorage.getItem("saved-scroll");
  var scrollable = document.querySelector(".scrollable");
  if (top !== null && scrollable !== null) {
    scrollable.scrollTop = parseInt(top, 10);
  }
});

window.addEventListener("turbolinks:before-render", () => {
  var scrollable =  document.querySelector(".scrollable");
  if(scrollable){
    localStorage.setItem("saved-scroll", scrollable.scrollTop);
  }
});

window.changeTab = function(tab) {
  document.getElementById("patient_file_clinicals_informations2").value = document.getElementById("patient_file_clinicals_informations").value;
  document.getElementById("patient_file_exam_code2").value = document.getElementById("patient_file_exam_code").value;
  if(tab !== undefined ){
    var selectedTabContent = document.getElementById(tab);
    var tabcontents = document.getElementsByClassName("tabcontent");
    var tablinks = document.getElementsByClassName("tablinks");

    for (var i = 0; i < tabcontents.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
      if(tabcontents[i] != selectedTabContent){
        tabcontents[i].style.display = "none";
      }else{
        tabcontents[i].style.display = "block";
        tablinks[i].className += " active";
      }
    }
    sessionStorage.setItem("selectedTab", tab);
  }
}
