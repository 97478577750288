import Rails from "@rails/ujs";
window.Rails = Rails;

window.updateClinicalsInformations = function(e) {
  document.getElementById("patient_file_clinicals_informations").value = e.currentTarget.value;
  document.getElementById("patient_file_clinicals_informations2").value = e.currentTarget.value;
}

window.updateExamCode = function(e) {
  document.getElementById("patient_file_exam_code").value = e.currentTarget.value;
  document.getElementById("patient_file_exam_code2").value = e.currentTarget.value;
}

document.onkeydown=function(e){
  if ((e.ctrlKey || e.metaKey) && e.key === 's'){
    e.preventDefault();
    var form = document.getElementById('patientForm');
    Rails.fire(form, 'submit');
  }
}

window.clear_prescription = function(e, patientId){
  var form = document.querySelector('form');
  var patient = new FormData(form);
  Rails.ajax({
    url: "/patient_files/" + patientId + "/clear_prescription",
    type: "post",
    data: patient
  })
}

window.sendPatient = function(e, patientId){
  if(confirm("Êtes-vous certain(e) de vouloir envoyer ce dossier à votre enseignant(e)?")){
    var form = document.querySelector('form');
    var patient = new FormData(form);
    Rails.ajax({
      url: "/patient_files/" + patientId + "/send_patient",
      type: "post",
      data: patient
    })
  }
}

window.returnPatient = function(e, patientId){
  if(confirm("Êtes-vous certain(e) de vouloir retourner ce dossier à votre étudiant(e)?")){
    var form = document.querySelector('form');
    var patient = new FormData(form);
    Rails.ajax({
      url: "/patient_files/" + patientId + "/return_patient",
      type: "post",
      data: patient
    })
  }
}

window.broadcastPatient = function(e, patientId){
  if(confirm("Êtes-vous certain(e) de vouloir envoyer ce dossier à tous vos étudiants(es)?")){
    var form = document.querySelector('form');
    var patient = new FormData(form);
    Rails.ajax({
      url: "/patient_files/" + patientId + "/broadcast_patient",
      type: "post",
      data: patient
    })
  }
}

window.toggleFullScreen = function(e){
  var element = document.getElementById("prescriptionImage");
  if(element.className == 'prescriptionImage'){
    element.className = 'fullscreen';
  }else{
    element.className = 'prescriptionImage';
  }
}
