
document.toggleMenu = function(e) {
    var menu = document.getElementById('menu-content');
    menu.style.visibility = menu.style.visibility == 'visible' ? 'hidden' : 'visible';
    e.stopPropagation();
}

document.stopPropagation = function(e){
  var senderId = e.path[0].id;
  if(senderId != "disconnect"){
    event.stopPropagation();
  }
}

document.closeMenu = function() {
  document.getElementById('menu-content').style.visibility = 'hidden';
}
